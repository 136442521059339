<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑VIP神券 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('vip.vipcfg/editsq')">保存 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<!-- 主栏 -->
					<el-col :xs="24" :sm="16">
						<!-- VIP神券 -->
						<div class="el-block">
							<div class="row-title">VIP神券设置</div>
							<div class="coupon-choose-form">
								<div class="title">
									<span class="tips"></span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
										@click="onItemAdd">增加神券</el-button>
								</div>
								<div v-for="idx in itemList.length" class="coupon-item">
									<span class="tips">选择神券</span>
									<el-select v-model="itemList[idx-1]" class="select" size="small" placeholder="请选择神券"
										clearable>
										<el-option v-for="item in couponList" :key="item.coupon_id"
											:label="item.coupon_name" :value="item.coupon_id">
										</el-option>
									</el-select>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
										@click="onItemDel(idx)">删除</el-button>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'vip-vipsq-edit',
		components: {},
		data() {
			return {
				fieldCode: 'vip_sq',
				device_id: '',
				// 表单信息
				formData: {},
				//数组
				itemList: [],
				// 表单规则
				rules: {},
				plug_ids: "",
				couponList: [],
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		methods: {
			init() {
				this.getCouponList();
				this.getVipcfg();
			},
			// 获取优惠券信息
			async getCouponList() {
				const params = {
					token: this.$store.state.user.token,
					status: 40,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Market.GetCouponList(params, {});
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
			},
			//获取配置
			async getVipcfg() {
				const params = {
					token: this.$store.state.user.token,
					fieldCode: this.fieldCode
				};
				const res = await this.$api.Vip.VipcfgIndex(params);
				this.itemList = res.fieldValue;
			},
			// 保存
			save(formName) {
				if (!this.checkItem()) return;
				this.$refs[formName].validate(async valid => {
					if (valid) {
						this.formData.fieldValue = JSON.stringify(this.itemList);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						await this.$api.Vip.VipcfgEditSq(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.getVipcfg();
					}
				})
			},
			//增加神券
			onItemAdd: function() {
				this.itemList.push(null);
			},
			//删除神券
			onItemDel: function(idx) {
				this.itemList.splice(idx - 1, 1);
			},
			//检测神券参数
			checkItem: function() {
				this.itemList.forEach(item => {
					if (item.coupon_id == 0 || item.coupon_id == null) {
						return false;
					}
				})
				return true;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.coupon-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.tips {
			font-size: 14px;
			color: #606266;
			padding: 6px 6px;
		}

		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 180px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.coupon-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
